import React, { createContext, useEffect, useState } from "react";
import langs from "../store/langs.json";

export const GlobalState = createContext<[string, any]>(["", null]);

export const REDIRECT_TIME_SECONDS = 15;
export const REDIRECT_TIME_MILLIS = REDIRECT_TIME_SECONDS * 1000;

function GlobalStateProvider({ children }: { children: React.ReactNode[] }) {
    const defaultLang = langs.length > 0 ? langs[0] : "en";
    const [lang, setLang] = useState(localStorage.getItem("global-lang") ?? defaultLang);

    useEffect(() => {
        localStorage.setItem("global-lang", lang);
    }, [lang]);

    return (
        <GlobalState.Provider value={[lang, setLang]}>
            {children}
        </GlobalState.Provider>
    );
}

export default GlobalStateProvider;
