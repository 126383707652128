import React, { useContext } from "react";
import { GlobalState } from "../store/store";
import langs from "../store/langs.json";
import appSettings from "../app-settings.json";

interface HeaderProps {
    title?: String;
}

function Header(headerProps: HeaderProps) {
    const [selectedLang, setSelectedLang] = useContext(GlobalState);

    if (langs.length < 2) {
        return (
            <div className="container-fluid text-right bg-black">
                <div className="row py-2">
                    <div className="col text-white">{headerProps.title || appSettings.title}</div>
                    <div className="col align-right"></div>
                </div>
            </div>
        );
    }

    const options = langs.map((lang) => {
        return <option key={lang} value={lang}>{lang}</option>;
    });

    const onChangeLang = (changeEvent: React.ChangeEvent<HTMLSelectElement>) => {
        const value = changeEvent.target.value;
        setSelectedLang(value);
    }

    return (
        <div className="container-fluid text-right bg-black">
            <div className="row py-2">
                <div className="col text-white">{appSettings.title}</div>
                <div className="col align-right">
                    <select value={selectedLang} onChange={onChangeLang}>
                        {options}
                    </select>
                </div>
            </div>
        </div>
    );
}

export default Header;
