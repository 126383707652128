export interface ILoginRes {
    success: boolean;
}

export async function api_login(email: string, password: string) {
    return new Promise((resolve, reject) => {
        const myHeaders = new Headers();

        myHeaders.set('Content-Type', 'application/json');

        const myData = {
            email,
            password,
        };

        const myInit: RequestInit = {
            method: 'POST',
            headers: myHeaders,
            mode: 'cors',
            cache: 'no-store',
            body: JSON.stringify(myData),
        };

        const myRequest = new Request(`/api/v1/login`, myInit);

        fetch(myRequest)
            .then(async (response) => {
                if (response.status != 200) {
                    console.error(`api_login: server returned ${response.status}`);
                    reject(response.status);
                    return
                }
                resolve("ok");
            })
            .catch(error => {
                console.error(`api_login: caught error ${error}`);
                reject(-1);
            })
            ;
    });
}
