{
    "en": {
        "welcome": "Welcome",
        "email-address": "Email Address:",
        "password": "Password:",
        "type-email": "Enter your email address",
        "type-password": "Enter your password",
        "password-required": "You must enter a password to log in.",
        "forgot-password": "Forgot password?",
        "connect": "Connect",
        "message": "To access this application, you must be identified. If you do not have a login, please contact the administrator by clicking on the following link:",
        "get-id": "ask for credentials"
    },
    "fr": {
        "welcome": "Bienvenue",
        "email-address": "Adresse email :",
        "password": "Mot de passe :",
        "type-email": "Saisissez ici votre adresse email",
        "type-password": "Saisissez ici votre mot de passe",
        "password-required": "Vous devez entrer votre mot de passe pour vous connecter.",
        "forgot-password": "Mot de passe oublié ?",
        "connect": "Se connecter",
        "message": "Pour accéder à cette application, vous devez être identifié. Si vous n'avez pas d'identifiant de connexion, veuillez contacter l'administrateur en cliquant sur le lien suivant :",
        "get-id": "demander un identifiant"
    }
}
