import React from "react";
import ReactDOM from "react-dom/client";

import Login from "../../components/login/Login";
import ChooseLang from "../../components/header/Header";
import GlobalStateProvider from "../../components/store/store";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <App></App>
    </React.StrictMode>
);

function App() {
    return <div className="page-login">
        <GlobalStateProvider>
            <ChooseLang />
            <Login />
        </GlobalStateProvider>
    </div>;
}