import React, { useContext, useState } from "react";
import { GlobalState } from "../store/store";
import { api_login } from "./api";
import translation from "./tr.json";
import appSettings from "../app-settings.json";

function Login() {
    const [selectedLang, _setSelectedLang] = useContext(GlobalState);
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<number>(0); // 0: default, 200: ok, 400: invalid username/password combination, 401: password required, else: unknown error

    const handleEMailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setEmail(value);
    };

    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setPassword(value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (password.length === 0) {
            setError(401);
            return;
        }

        setError(200);
        api_login(email, password)
            .then((res) => {
                window.location.reload();
            })
            .catch(setError);
    };

    function passwordRequiredEl() {
        return (
            <div id="login-form-passwordFeedback">
                <small className="text-danger">{t["password-required"]}</small>
            </div>
        );
    }

    const lang = selectedLang;
    const t: any = (translation as Record<string, any>)[lang];

    return (
        <div className="container">
            <form
                className="row custom-centered mt-2 g-3 needs-validation"
                onSubmit={handleSubmit}
            >
                {/* <img src={"/img/" + appSettings.logo} alt="logo" /> */}

                <h3>{t.welcome}</h3>
                <p className="text-muted">
                    {t["message"]}
                    &nbsp;
                    <a href="#" className="text-primary">
                        {t["get-id"]}
                    </a>
                    .
                </p>

                <div className="form-group">
                    <label htmlFor="login-form-email">{t["email-address"]}</label>
                    <div className="input-group has-validation">
                        <label htmlFor="login-form-email" className="input-group-addon">
                            <span className="oi oi-envelope-closed" aria-hidden="true"></span>
                        </label>
                        <input
                            id="login-form-email"
                            type="email"
                            className="form-control is-invalid-todo"
                            placeholder={t["type-email"]}
                            value={email}
                            onChange={handleEMailChange}
                            autoComplete="username"
                            required
                        />
                    </div>
                    <div id="login-form-emailFeedback" className="invalid-feedback">
                        <small className="text-danger">Invalid email address</small>
                    </div>
                </div>

                <div className="form-group mt-2">
                    <label htmlFor="login-form-password">{t["password"]}</label>
                    <div className="input-group has-validation">
                        <label htmlFor="login-form-password" className="input-group-addon">
                            <span className="oi oi-key" aria-hidden="true"></span>
                        </label>
                        <input
                            id="login-form-password"
                            type="password"
                            className={["form-control", (error != 200) && (error != 0) ? "is-invalid" : ""].join(" ")}
                            placeholder={t["type-password"]}
                            autoComplete="current-password"
                            value={password}
                            onChange={handlePasswordChange}
                        />
                    </div>
                    {error === 401 && passwordRequiredEl()}
                </div>

                <div className="form-group mt-4">
                    <div className="row">
                        <div className="col">
                            <a href="/forgot" className="text-primary">
                                {t["forgot-password"]}
                            </a>
                        </div>
                        <div className="col align-right">
                            <button type="submit" className="btn btn-primary">
                                {t["connect"]}
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Login;
